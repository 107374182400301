import axios from '../service/axios'
import qs from 'querystring'
import { clearLoginInfo } from './index'
import { Message } from 'element-ui'

let base64 = require('js-base64').Base64
/**
 * http请求
 */

export default class httpUtil {

	/**
	 * get请求
	 * @param url
	 * @param params
	 * @returns {Promise<R>}
	 */



	static get(url, params) {
		return new Promise(async (resolve, reject) => {
			try {
				let query = await qs.stringify(params);
				let res = null;
				if (!params) {
					res = await axios.get(url);
				} else {
					res = await axios.get(url + '?' + query);
				}
				resolve(res);
			} catch (error) {
				let errorMsg = `请求报错路径： ${url} \n 请求错误信息: ${error}`;
				console.log(errorMsg)
				reject(error);
			}
		});
	}

	/**
	 * POST请求
	 * @param url
	 * @param params
	 * @returns {Promise<R>}
	 */
	static post(url, params, type) {
		return new Promise(async (resolve, reject) => {
			try {
				// console.log('params:',params)
				const data  = {}
				if (params) {
					let query = base64.encode(JSON.stringify(params));
					data.data = query;
				}
				/* let query = base64.encode(JSON.stringify(params));
				const data  = {
					data: query,
				} */
				console.log('>>>>>>> url: {}, data: {}, type: {}', url, data, type)
				let res = ''
				
				if (url && url.indexOf("getPcLoginImg") > 1) {
					res = await axios.post( url );
				} else {
					if(params && url && url.indexOf("pcLoginCallBack") == -1 ){
						res = await axios.post( url, qs.stringify(data),{headers:type});
						//可以调整为白名单数组
					} else if(url && (url.indexOf("pcLoginCallBack") != -1 || url.indexOf("componentCallBack") != -1 )){
						//登录回调
						console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>')
						res = await axios.post( url, qs.stringify(params) ,{headers:type});
					}else{
						res = await axios.post( url );
					}
				}
				
				
				/* if(params && url && url.indexOf("pcLoginCallBack") == -1 ){
					res = await axios.post( url, qs.stringify(data),{headers:type});
					//可以调整为白名单数组
				} else if(url && (url.indexOf("pcLoginCallBack") != -1 || url.indexOf("componentCallBack") != -1 )){
					//登录回调
					console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>')
					res = await axios.post( url, qs.stringify(params) ,{headers:type});
				}else{
					res = await axios.post( url );
				} */
				/*
        *   SUCCESS(200,"成功"),
            ERROR(500,"系统异常"),
            FAULT_TOLERANT(501,"容错"),
            FAIL(201,"操作失败"),
            NO_LOGIN(401,"未登陆"),
            NO_ROLE(403,"无权限"),
            NO_PARAM(202,"参数缺失"),
            PARAM_CHECK(203,"参数校验失败"),
            NO_DATA(204,"无数据"),
            TEST(999999,"TEST")
        * */
				if (
					res.code == 200 ||
					res.code == 209 ||
					res.code == 204

				) {
					resolve(res);
				} else if (res.code == 401) {
					clearLoginInfo();
					reject(res.data);
				} else if(res.code == 201){
					reject(res);
				} else {
					reject(res.data);
						let { message } = res;
						message = message
							? message === "GENERAL"
								? "系统繁忙"
								: message
							: "系统繁忙";
						Message({
							message:message,
							type:'warning'
						})
					// try{
					// 	//检测
					// 	// const blob = new Blob([res], {type: 'application/vnd.ms-excel'});
					// 	return
					// 	resolve(res);
					// }catch (e) {
						
					// }
				}
				//console.log('params:',res)
				resolve(res);
			} catch (error) {
				let errorMsg = `请求报错路径：${url} \n 请求错误信息: ${error}`;
				reject(errorMsg);
			}
		});
	}
}
